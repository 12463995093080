import React from 'react';

const Footer = () => {
  return (
    <div class="p-4 text-sm  text-gray-500 bg-gray-100">
      Website by Brackett Design
    </div>
  );
};

export default Footer;